export default {
    platForm: '新特教智慧服务平台',
    subPlatform: 'New Special Education Platform',
    teachType: {1: '送教上门', 2: '随班就读', 3: '特教学校'},
    logo: 'https://img.cloudaware.cn/iep/20231018/15f7b5c8-f2e6-4c16-ac11-de1aa3fdc862.png',
    replaceAvatar: 'http://img.cloudaware.cn/iep/20231030/8ed2e1bd-36c4-4da2-bbec-00f9cb0f053e.png',
    stateType: {1: '正常', 2: '禁用'},
    //职称
    profession: ['正高级教师', '高级教师', '一级教师', '二级教师', '未定级'],
    //    学历
    eduBack: ['博士', '硕士', '本科', '专科', '高中及以下'],
    //    预约类型
    courseOpt: ['巡回指导', '专家咨询'],
    //    教师类型
    teacher: {1: '资源教师', 2: '送教上门教师', 3: '巡回指导教师', 4: '特教教师', 5: '专家委员', 6: '其他'},
    //    学期
    halfType: {1: '第一学期', 2: '第二学期'},
    week: {1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日'},
    //请假时长
    leaveTime: {1: '上午', 2: '下午', 3: '全天'},
    //    残疾证等级
    disabilityGrade: {1: '一级', 2: '二级', 3: '三级', 4: '在办'},
    gradeOpt: {
        "幼儿": [
            "小班",
            "中班",
            "大班"
        ],
        "小学": [
            "一年级上",
            "一年级下",
            "二年级上",
            "二年级下",
            "三年级上",
            "三年级下",
            "四年级上",
            "四年级下",
            "五年级上",
            "五年级下",
            "六年级上",
            "六年级下"
        ],
        "中学": [
            "七年级上",
            "七年级下",
            "八年级上",
            "八年级下",
            "九年级上",
            "九年级下"
        ],
        "职业": [
            "职高一年级",
            "职高二年级",
            "职高三年级"
        ]
    },
    statusOpt: {1: '待审核', 2: '审核通过', 3: '审核不通过'},
    monthOptions: {
        1: {
            '8月': '8',
            '9月': '9',
            '10月': '10',
            '11月': '11',
            '12月': '12',
            '1月': '1',
            '整个学期': '13'
        }, 2: {'2月': '2', '3月': '3', '4月': '4', '5月': '5', '6月': '6', '7月': '7', '整个学期': '13'}
    },
    evaluateScale: {
        1: '婴儿—初中生社会生活能力量表',
        2: '儿童感觉统合能力发展评定量表',
        3: '父母教养方式量表',
        4: 'PEP-3',
        5: 'GMFM-88',
        6: 'FMFM',
        7: '韦氏智力四版测试',
        8: '语言与沟通能力评估',
        9: '蹲站走协调能力评估'
    },
    courseType: {1: '集体课', 2: '个训及组训课'},
    formatArr: ['doc', 'docx', 'pptx', 'xls', 'xlsx', 'xlsm'],
    otherFormatArr: ['png', 'jpg', 'jpeg', 'webp', 'gif', 'txt', 'mp3', 'mp4', 'mpg', 'mpeg', 'wmv', 'mov', 'rm', 'ram', 'pdf'],
    satisOpt: ['非常满意', '满意', '不满意'],
    guidOpt: ['不足', '合格', '良好', '优秀'],
    platType: {1: '特教', 2: '融合教育平台', 3: '送教上门', 4: '教育局', 5: '专家委员'},
    map: {
        "苏州": {
            id: 4430941,
            mapData: '/map/suzhou-map.json'
        },
        "平凉": {
            id: 2696771,
            mapData: '/map/pingliang-map.json'
        },
        "阜阳": {
            id: 3246004,
            mapData: '/map/fuyang-map.json'
        },
        "连云港": {
            id: 3320347,
            mapData: '/map/lianyungang-map.json'
        },
        "南通": {
            id: 4430899,
            mapData: '/map/nantong-map.json'
        }
    },
}
